import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	display: block;
	width: 100%;
	font-family: Ubuntu, sans-serif;
`;

const Title = styled.h4`
	display: block;
	text-align: center;
	font-weight: normal;
	font-size: 1.25rem;
	background: rgb(255, 221, 0);
	border-radius: 2px;
	padding: 1rem;
	font-family: Ubuntu, sans-serif;
`;

const Content = styled.div`
	display: block;
	padding: 0.25rem 0;
	
	p {
		font-size: 1em;
		margin: 0.5rem;
		line-height: 1.5em;
	}
	
	ul {
		display: block;
		list-style-type: disc;
		margin: 1em;
		padding-left: 1.75em;
		
		> li {
			margin: 0.5em 0;
		}
	}
`;

const Product = function (props) {
	const {title, children} = props;

	return (
		<Wrapper>
			<Title>{title}</Title>
			<Content>{children}</Content>
		</Wrapper>
	);
};

export default Product;
