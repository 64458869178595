import React from 'react';
import styled from 'styled-components';

import Heading2 from './Heading2';
import Product from './Product';

const Wrapper = styled.section`
	display: block;
	padding: 3rem;
	font-family: Ubuntu, sans-serif;
`;

const P = styled.p`
	display: block;
	text-align: center;
	padding: 0.5rem 1rem;
	font-size: 1.25em;
`;

const Flexbox = styled.div`
	display: block;
	
	@media (min-width: 767px) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-content: stretch;
		
		> div {
			width: 50%;
			padding: 0.5em;
		}
	}
	
	@media (min-width: 1200px) {
		width: 70vw;
		margin: 0 calc(50% - 35vw);
	
		> div {
			width: 33.33333%;
		}
	}
`;

const Products = function () {
	return (
		<Wrapper>
			<Heading2>
				We're an independent development &amp; consulting agency
			</Heading2>
			<P>
				And we can help you with:
			</P>
			<Flexbox>
				<div>
					<Product title="Backend development">
						<p>Using:</p>
						<ul>
							<li>PHP</li>
							<li>Node.js</li>
							<li>PostgreSQL</li>
							<li>MySQL/MariaDB</li>
						</ul>
					</Product>
				</div>
				<div>
					<Product title="Frontend development">
						<p>Using:</p>
						<ul>
							<li>React.js</li>
							<li>Redux</li>
							<li>React router</li>
							<li>Styled components</li>
							<li>CSS/SASS/LESS</li>
							<li>ES5/ES6/ES7/ES8 and on</li>
							<li>... and many other from React ecosystem</li>
						</ul>
					</Product>
				</div>
				<div>
					<Product title="Development organisation">
						<ul>
							<li>how to organise your development team</li>
							<li>which tools to use</li>
							<li>webpack setup and other tools setup</li>
							<li>development procedures organisation</li>
						</ul>
					</Product>
				</div>
				<div>
					<Product title="Dev-ops">
						<p>
							We use Linux and on Linux, we can help you with: </p>
						<ul>
							<li>project setup automation</li>
							<li>continuous integration (CI)</li>
							<li>infrastructure security setup and analysis</li>
							<li>infrastructure cost analysis</li>
						</ul>
					</Product>
				</div>
				<div>
					<Product title="Business analysis">
						<ul>
							<li>analyse your business processes and turn it into technical requirements</li>
							<li>check your technical documentation to see if it fits your business needs</li>
							<li>development cost analysis</li>
						</ul>
					</Product>
				</div>
				<div>
					<Product title="Software &amp; server architecture">
						<p>
							By using all our knowledge from product idea, business requirements, technical
							requirements, security and software development, we can do: </p>
						<ul>
							<li>software/system architecture</li>
							<li>network organisation and servers setup</li>
						</ul>
						<p>
							This is all done with <strong>security</strong> on <strong>first place</strong>, then with the <strong>cost</strong> in mind.
						</p>
					</Product>
				</div>
			</Flexbox>
		</Wrapper>
	);
};

export default Products;
