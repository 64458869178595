import React from "react"
import styled from "styled-components"

import Heading2 from "./Heading2"

const Wrapper = styled.section`
	display: block;
  background-color: rgb(255, 221, 0);
  padding: 3em 1rem;
  text-align: center;
	margin: 0;
	line-height: 1.8;
	font-family: Ubuntu, sans-serif;
	
	> div {
		display: block;
	  width: 100%;
	  max-width: 800px;
	  margin: 0 auto;
	}
	
	a {
		color: #000000;
	}
`

const AboutUs = function() {
  return (
    <Wrapper>
      <div>
        <Heading2>About us</Heading2>
        <div>
          We are small company from <a href="https://goo.gl/maps/g8aUbqgKFPe74dSy9" target="_blank" rel="noopener noreferrer">Varaždin,
          Croatia</a> and we're doing our best in making cool and useful web apps. If you'd like to hire us for
          development, consultations or have any other question, <a href="https://www.linkedin.com/in/vlatkokoudela" target="_blank" rel="noopener noreferrer">contact
          Vlatko Koudela via LinkedIn</a> to check availability.
        </div>
      </div>
    </Wrapper>
  )
}

export default AboutUs
