import React from 'react';
import styled from 'styled-components';

const StyledHeading = styled.h2`
	display: block;
	font-size: 2em;
	font-weight: normal;
	color: #444444;
	text-align: center;
	padding: 1rem;
	font-family: Ubuntu, sans-serif;
`;

const Heading2 = function (props) {
	const {children} = props;

	return (
		<StyledHeading>
			{children}
		</StyledHeading>
	);
};

export default Heading2;
