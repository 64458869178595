import React from 'react';
import styled from 'styled-components';

import skyscraper from '../images/skyscrapers.jpg';

const StyledIntro = styled.div`
	width: 100%;
	max-width: 100%;
	height: 70vh;
	background: url(${skyscraper}) no-repeat center center;
	object-fit: cover;
	background-size: cover;
	display: flex;
	align-items: center;
	text-align: center;
	position: relative;
	padding: 0 1em;
	box-sizing: border-box;
	font-family: Ubuntu, sans-serif;
	
	&:hover {
		cursor: default;
	}
	
	&:before {
		content: " ";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.4);
		z-index: 1;
	}
	
	> div {
		display: block;
		width: 100%;
		color: #ffffff;
		z-index: 2;
		
		> span {
			display: block;
			font-size: 1.25em;
			color: rgba(245, 67, 37, 0);
			
			&::selection {
				color: #ffffff;
				background: transparent;
			}
		}
		
		> blockquote {
			display: block;
			font-size: 1.25em;
			font-weight: normal;
			margin: 0;
			padding: 0;
			text-shadow: 0 0 3px rgba(22, 22, 22, .5);
			line-height: 1.5;
		}
	}
	
	h1 {
	  display: block;
    font-size: 3em;
    font-weight: 400;
    text-shadow: 0 0 3px rgba(22, 22, 22, .5);
    padding: 1rem;
    font-family: Ubuntu, sans-serif;
	}
`;

const Intro = function () {
	return (
		<StyledIntro>
			<div>
				<h1>Splendido Solutions</h1>
				<span>Good for you to read between the lines!</span>
				<blockquote>
					Making cool apps using cutting edge web technologies
				</blockquote>
				<blockquote>
					<small>Since 2015.</small>
				</blockquote>
			</div>
		</StyledIntro>
	);
};

export default Intro;
